import React from "react";
import PageWrapper from "../components/PageWrapper";
import Content1 from "../sections/propertySol/Content1";
import Hero from "../sections/propertySol/Hero";
import Wnapa from "../sections/propertySol/Wnapa";
import Call from "../sections/propertySol/Call";
import Testimonial from "../sections/propertySol/Testimonial";
const propSol = () => {
  return (
    <>
      <PageWrapper footerDark headerDark>
        <Hero />
        <Content1 />
        <Testimonial />
        <Wnapa />
        <Call />
      </PageWrapper>
    </>
  );
};
export default propSol;
